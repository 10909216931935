/* LAYOUT */
.page-container {
  margin: 32px;
}

.flex {
  display: flex;
  flex: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.align-start {
  align-content: start;
}

.text-center {
  text-align: center;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  margin: 16px;
  padding: 32px;
  transition: 0.3s;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid black;
  padding: 16px;
  transition: 0.3s;
}

.gray-button {
  z-index: 1;
  background-color: #c5c7c6;
  /* height: 30px; */
  max-width: 200px;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 32px;
  margin: 0px 8px;
}
