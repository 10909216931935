/* The Modal (background) */
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 2;
  right: 0;
  top: 0;
  overflow: hidden; /* Hide overflow to prevent interaction */
  background-color: rgba(0, 0, 0, 0.4);
  transition: transform 0s;
  transform: scale(0); /* Start with scale(0) to hide it */
  transform-origin: center center; /* Set the transform origin to the center */
}

/* Modal Content/Box */
.modal-content {
  overflow-y: auto;
  /* background-color: var(--background); */
  background-color: var(--secondary-light);
  margin: 0 auto; /* Centered horizontally */
  padding: 16px;
  width: 50%; /* Could be more or less, depending on screen size */
  border-radius: 16px;
  transition: transform 0.5s, height 0.5s; /* Use transform and height for transition */
  /* transform: scale(0); Start with scale(0) to hide it */
  transform-origin: center center; /* Set the transform origin to the center */
  max-height: 80vh;
}

.show-modal {
  display: flex;
  height: 100%; /* Expand the height to 100% for full-screen effect */
  transform: scale(1); /* Transition from scale(0) to scale(1) for expanding */
}
