body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  min-width: 60%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px; /* Add more space between cells */
  text-align: center; /* Center text in cells */
  font-size: 16px; /* Increase text size */
  color: black; /* Set text color to black */
}

th {
  background-color: #f2f2f2; /* Light gray background for header */
}

/* 
button {
  z-index: 1;
  background-color: '#c5c7c6';
  height: '30px';
  border: '0px';
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  padding: '0px 32px';
  margin: '0px 32px';
} */

button:hover {
  transform: scale(1.05);
  opacity: 1;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
}
